#speedometer .wrapper {
  width: 250px;
  height: 125px;
  border-top-left-radius: 360px;
  border-top-right-radius: 360px;
  overflow: hidden;
  position: relative;
}

#speedometer .wrapper .indicator-wrapper {
  position: absolute;
  left: 20%;
  top: 40%;
  width: 60%;
  height: 60%;
  border-top-left-radius: 360px;
  border-top-right-radius: 360px;
  background-color: black;
  z-index: 400;
}

#speedometer .wrapper .bar {
  width: 100%;
  height: 100%;
  position: absolute;
  transform-origin: bottom center;
}
//36
#speedometer .wrapper .bar-1 {
  top: 0;
  left: 0;
  z-index: 200;
  transform: rotate(-144deg);
  /* background-color: rgb(114, 114, 238); */
  background-color: #7ab800;
}

#speedometer .wrapper .bar-2 {
  top: 0;
  right: 0;
  z-index: 150;
  transform: rotate(-110deg);
  /* background-color: rgb(69, 69, 204); */
  background-color: #f2af00;
}

#speedometer .wrapper .bar-3 {
  top: 0;
  left: 0;
  z-index: 100;
  transform: rotate(-72deg);
  /* background-color: rgb(40, 40, 180); */
  background-color: #dc5034;
}

#speedometer .wrapper .bar-4 {
  top: 0;
  left: 0;
  z-index: 0;
  transform: rotate(-36deg);
  /* background-color: rgb(15, 15, 167); */
  background-color: #ce1126;
}

#speedometer .wrapper .bar-5 {
  top: 0;
  left: 0;
  z-index: 0;
  transform: rotate(144deg);
  /* background-color: rgb(15, 15, 167); */
  background-color: #b7295a;
}

.needle {
  width: 10px;
  height: 110px;
  background: black;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  border-top-left-radius: 100%;
  border-top-right-radius: 100%;
  display: inline-block;
  position: absolute;
  bottom: 0;
  transform-origin: bottom;
}
