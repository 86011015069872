.topBar {
  background-color: #983838;
  width: calc(100vw - 2rem);
  color: white;
  padding: 0.25rem 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
}

.playing-time-container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
}

.title {
  font-size: 16px;
  font-weight: bold;
  margin: 0;
}

.star-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.timer {
  width: 250px;
  height: 30px;
  background: rgba(0, 0, 0, 0.5);
  box-shadow: inset 2px 2px 4px rgba(0, 0, 0, 0.25);
  border-radius: 50px;
  position: relative;
}

.inner-timer {
  width: 80%;
  height: 30px;
  background: #ffa800;
  box-shadow: inset 4px 4px 10px rgba(0, 0, 0, 0.25);
  border-radius: 50px;
}

.timer-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 16px;
  font-weight: bold;
  margin: 0;
}
