.button {
  background-color: #ffa800;
  padding: 1rem 2rem;
  color: white;
  font-size: 18px;
  font-weight: bold;
  box-shadow: inset -5px -5px 5px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.loading-spinner {
  width: 15px;
  height: 15px;
  border: 3px solid #ffffff;
  border-top: 3px solid transparent;
  border-radius: 50%;
  animation: spinner 1.5s linear infinite;
}
