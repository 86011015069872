@import url("https://fonts.googleapis.com/css2?family=Mr+Dafoe&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Exo:wght@900&display=swap");

.container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  perspective: 700px;
  font-size: clamp(10px, 1vw, 20px);
  overflow: hidden;
  min-height: 250px;
}

.lines {
  $color: #59c1fe;
  position: fixed;
  width: 100vw;
  height: 4em;
  background: linear-gradient(
    rgba($color, 0.2) 20%,
    $color 40%,
    $color 60%,
    rgba($color, 0.2) 80%
  );
  background-size: 1px 0.5em;
  box-shadow: 0 0 1em rgba($color, 0.4);
  transform: translateY(-1em);
  left: 0;
}

h1 {
  width: 100vw;
  font-family: "Exo";
  font-size: 7em;
  margin: 0;
  transform: skew(-15deg);
  letter-spacing: 0.03em;

  &::after {
    content: "";
    position: absolute;
    top: -0.1em;
    right: 0.05em;
    width: 0.4em;
    height: 0.4em;
    background: radial-gradient(
        white 3%,
        rgba(white, 0.3) 15%,
        rgba(white, 0.05) 60%,
        transparent 80%
      ),
      radial-gradient(rgba(white, 0.2) 50%, transparent 60%) 50% 50% / 5% 100%,
      radial-gradient(rgba(white, 0.2) 50%, transparent 60%) 50% 50% / 70% 5%;
    background-repeat: no-repeat;
  }

  span:first-child {
    display: block;
    text-shadow: 0 0 0.1em #8ba2d0, 0 0 0.2em black, 0 0 5em #165ff3;
    -webkit-text-stroke: 0.06em rgba(black, 0.5);
  }

  span:last-child {
    width: 100vw;
    position: absolute;
    left: 0;
    top: 0;
    background-image: linear-gradient(
      #032d50 25%,
      #00a1ef 35%,
      white 50%,
      #20125f 50%,
      #8313e7 55%,
      #ff61af 75%
    );
    -webkit-text-stroke: 0.01em #94a0b9;
    background-clip: text;
    -webkit-text-fill-color: transparent;
  }
}
