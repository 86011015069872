.rank-board {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #983838;
  border-radius: 1rem;
  width: 100%;
  max-width: 750px;
  position: absolute;
  z-index: 15;
}

.rank-board-container {
  width: calc(100% - 2rem);
  background: #bc4d4d;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 1rem;
  border-radius: 0 0 1rem 1rem;
  padding: 16px 16px;
  max-height: 500px;
  overflow: auto;
}

::-webkit-scrollbar {
  width: 10px;
}
::-webkit-scrollbar-track {
  margin: 10px;
  background: transparent;
}
::-webkit-scrollbar-thumb {
  background: rgba(255, 255, 255, 1);
  border-radius: 1rem;
}
::-webkit-scrollbar-thumb:hover {
  background: rgba(255, 255, 255, 0.75);
}

.rank-board-bg {
  width: 100%;
  height: 100%;
  position: absolute;
  background-color: rgba(black, 0.4);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 1;
}

.rank-board-title {
  font-size: 20px;
  font-weight: bold;
  color: white;
  margin: 14px;
}

.crown-icon {
  position: absolute;
  left: -55px;
  top: -100px;
}
