.level-selection {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #983838;
  border-radius: 1rem;
}

.levels-container {
  background: #bc4d4d;
  display: flex;
  gap: 1rem;
  padding: 1rem;
  border-radius: 0 0 1rem 1rem;
}

.level-selection-bg {
  width: 100%;
  height: 100%;
  position: absolute;
  background-color: rgba(black, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.level-selection-title {
  font-size: 20px;
  font-weight: bold;
  color: white;
  margin: 14px;
}
