.game-result {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #983838;
  border-radius: 1rem;
  width: 100%;
  max-width: 350px;
  position: absolute;
  z-index: 15;
}

.game-result-container {
  width: calc(100% - 2rem);
  background: #bc4d4d;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 1rem;
  border-radius: 0 0 1rem 1rem;
  padding: 32px 16px;
}

.game-result-bg {
  width: 100%;
  height: 100%;
  position: absolute;
  background-color: rgba(black, 0.5);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 1;
}

.game-result-title {
  font-size: 20px;
  font-weight: bold;
  color: white;
  margin: 14px;
}

.login-buttons-container {
  display: flex;
  justify-content: space-around;
  gap: 16px;
}
