.level-card {
  background: white;
  box-shadow: inset -5px -5px 5px rgba(0, 0, 0, 0.15);
  border-radius: 1rem;
  padding: 2rem 1rem;
  cursor: pointer;
}

.level-card-title {
  font-size: 20px;
  font-weight: bold;
  margin: 0;
}
