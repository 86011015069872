.game-result {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #983838;
  border-radius: 1rem;
  width: 100%;
  max-width: 350px;
  position: absolute;
  z-index: 9;
}

.game-result-container {
  width: calc(100% - 2rem);
  background: #bc4d4d;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 1rem;
  border-radius: 0 0 1rem 1rem;
  padding-bottom: 3rem;
}

.game-result-bg {
  width: 100%;
  height: 100%;
  position: absolute;
  background-color: rgba(black, 0.5);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.game-result-title-container {
  width: calc(100% - 1rem);
  display: flex;
  gap: 0.5rem;
  justify-content: center;
  align-items: center;
  margin: 0 0.5rem;
}

.game-result-title {
  font-size: 20px;
  font-weight: bold;
  color: white;
  margin: 14px;
}

.result-text {
  width: 100%;
  color: white;
  display: flex;
  gap: 1rem;
  justify-content: space-between;
  font-weight: bold;
}

.result-text > * {
  margin: 0;
}

.seperator {
  border-bottom: 1px rgba(white, 0.5) solid;
}
